<!--
 * @Description: 新增、修改费用
 * @Author: 琢磨先生
 * @Date: 2022-11-17 14:41:37
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-08 11:41:58
-->

<template>
  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="800px"
    top="10vh"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    draggable
    @closed="onClosed"
    :close-on-press-escape="false"
  >
    <el-form v-loading="loading" :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="订单类型" prop="order_type">
        <el-radio-group v-model="form.order_type">
          <el-radio :label="0">普通</el-radio>
          <el-radio :label="1">试住</el-radio>
          <el-radio :label="2">自住</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="费用项" prop="expense_item_id">
        <el-select v-model="form.expense_item_id" @change="expenseChange">
          <el-option v-for="item in expenseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房源房号" prop="storeIds">
        <el-cascader
          :options="huxing_list"
          v-model="form.storeIds"
          style="width: 300px"
          filterable
          @change="changeStore"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="数量">
        <el-col :span="5">
          <el-input v-model="form.number" placeholder @input="numberChange"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="单价(元)" prop="decimal_unit_price">
        <el-col :span="8">
          <el-input v-model="form.decimal_unit_price" placeholder @input="priceInput">
            <template #suffix>{{selection_expense?selection_expense.unit:''}}</template>
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="费用总额(元)" prop="decimal_total_amount">
        <el-col :span="10">
          <el-input v-model="form.decimal_total_amount" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="业主占比" prop="decimal_ratio">
        <el-col :span="6">
          <el-input v-model="form.decimal_ratio" placeholder>
            <template #suffix>%</template>
          </el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="成本总额(元)" prop="decimal_cost_amount">
        <el-col :span="10">
          <el-input v-model="form.decimal_cost_amount" placeholder></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="费用日期" prop="pay_date">
        <el-date-picker
          v-model="form.pay_date"
          type="date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :disabled-date="(date) => date > new Date()"
          placeholder
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="备注">
        <el-col :span="16">
          <el-input v-model="form.remark" placeholder type="textarea"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="凭证图片">
        <upload-gallery :pictures="form.pic_urls" @change="uploadChange"></upload-gallery>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import expense_api from "@/http/expense_api";
import UploadGallery from "../../psd/upload_gallery.vue";
import huxing_api from "@/http/huxing_api";
import common_api from "@/http/common_api";
export default {
  components: {
    UploadGallery,
  },
  data() {
    return {
      loading: false,
      title: "",
      //显示导入弹框
      visibleDialog: false,
      saving: false,
      //
      enums: {},
      form: {
        order_type: 0,
      },
      rules: {
        order_type: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        decimal_total_amount: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        expense_item_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        decimal_ratio: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        storeIds: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        pay_date: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      //费用项目
      expenseList: [],
      //选择的费用项
      selection_expense: null,
      //房型options
      huxing_list: [],
    };
  },
  props: ["id", "visible"],
  emits: ["success", "close"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.title = "修改费用支出";
          this.loadData();
        } else {
          this.title = "新增费用支出";
        }
      },
      immediate: true,
    },
    visible: {
      handler() {
        this.visibleDialog = this.visible;
        if (!this.id) {
          this.form = {
            order_type: 0,
            number: 1,
          };
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    expense_api.get_expenses().then((res) => {
      if (res.code == 0) {
        this.expenseList = res.data;
      }
    });

    huxing_api.get_cascader().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            value: x.id,
            label: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              value: item.id,
              label: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });
  },
  methods: {
    /**
     * 费用项选择更改
     */
    expenseChange() {
      this.selection_expense = this.expenseList.find(
        (o) => o.id == this.form.expense_item_id
      );
      this.loadGetRatio();
    },
    /**
     * 房号选择更改
     */
    changeStore() {
      this.loadGetRatio();
    },

    /**
     * 数量更改
     */
    numberChange() {
      if (!isNaN(this.form.number)) {
        var num = Number(this.form.number);
        if (
          this.form.decimal_unit_price &&
          !isNaN(this.form.decimal_unit_price)
        ) {
          this.form.decimal_total_amount =
            Math.floor(parseFloat(this.form.decimal_unit_price * num * 100)) /
            100;
        }
      }
    },
    /**
     * 单价输入更改
     */
    priceInput() {
      if (!isNaN(this.form.number)) {
        var num = Number(this.form.number);
        if (
          this.form.decimal_unit_price &&
          !isNaN(this.form.decimal_unit_price)
        ) {
          this.form.decimal_total_amount =
            Math.floor(parseFloat(this.form.decimal_unit_price * num * 100)) /
            100;
        }
      }
    },

    /**
     * 获取费用比例
     */
    loadGetRatio() {
      if (
        this.form.expense_item_id &&
        Array.isArray(this.form.storeIds) &&
        this.form.storeIds.length > 0
      ) {
        this.loading=true;
        this.$http
          .post("seller/v1/expense/get_ratio", {
            expense_item_id: this.form.expense_item_id,
            store_id: this.form.storeIds[this.form.storeIds.length - 1],
            order_type: this.form.order_type,
          })
          .then((res) => {
            if (res.code == 0) {
              this.form.decimal_ratio = res.data.decimal_ratio;
              this.form.decimal_unit_price = res.data.decimal_unit_price;
            }
          }).finally(()=>{
            this.loading=false;
          });
      } else {
        this.form.decimal_ratio = "0";
      }
    },

    /**
     * 加载详情
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("seller/v1/expense/record/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            var item = res.data;
            this.form.id = item.id;
            this.form.storeIds = [item.store.group_id, item.store.id];
            this.form.expense_item_id = item.expense_item_id;
            this.form.order_type = item.order_type;
            this.form.unit_price = item.unit_price;
            this.form.number = item.number;
            this.form.decimal_total_amount = item.decimal_total_amount;
            this.form.remark = item.remark;
            this.form.pay_date = item.pay_date;
            this.form.pic_urls = item.pic_urls;
            this.form.decimal_cost_amount = item.decimal_cost_amount;
            this.form.house_no = item.store.house_no;
            this.form.decimal_ratio = item.decimal_ratio;
            this.form.decimal_unit_price = item.decimal_unit_price;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 图片上传回调
     * @param {*} urls
     */
    uploadChange(urls) {
      this.form.pic_urls = urls;
    },
    /**
     * 提交保存
     */
    onSubmit() {
      if (!this.form.id) {
        if (!this.form.storeIds) {
          return;
        }
        if (this.form.storeIds.length <= 1) {
          this.$message.error("请选择房间");
          return;
        }
      }
      this.form.house_store_id =
        this.form.storeIds[this.form.storeIds.length - 1];
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/expense/record/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },

    /**
     * 关闭后执行
     */
    onClosed() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
</style>